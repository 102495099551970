import { CoreMenu } from '@core/types';


export const menu: CoreMenu[] = [
  {
    id: 'processmaps',
    title: 'Process Maps',
    type: 'collapsible',
    icon: 'home',
    children: [
      {
        id: 'erdprocessmap',
        title: 'ERP Process Map',
        type: 'item',
        role: ['Admin'], 
        icon: 'circle',
        url: 'apps/processmap/pm-erp'
      }
    ]
  },
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'collapsible',
    icon: 'box',
    children: [
      {
        id: 'analytics',
        title: 'Analytics',
        type: 'item',
        role: ['Admin'], //? To set multiple role: ['Admin', 'Client']
        icon: 'circle',
        url: 'dashboard/analytics'
      }
    ]
  },
  {
    id: 'modules',
    type: 'section',
    title: 'Modules',
    icon: 'package',
    children: [
      {
        id: 'acctgsys',
        title: 'Accounting Entries',
        type: 'collapsible',
        icon: 'box',
        children: [
          {
            id: 'acctbank',
            title: 'Banking',
            type: 'collapsible',
            icon: 'circle',
            children: [
              {
                id: 'acctbankacct',
                title: 'Account',
                type: 'item',
                icon: 'square',
                // url: 'apps/accounting/entries/banking/Account'
                url: 'apps/processmap/pm-erp'
              },
              {
                id: 'acctbanktransfer',
                title: 'Transfer',
                type: 'item',
                icon: 'square',
                // url: 'apps/accounting/entries/banking/Transfer'
                url: 'apps/processmap/pm-erp'
              }
            ]
          },

          {
            id: 'acctincome',
            title: 'Income',
            type: 'collapsible',
            icon: 'circle',
            children: [
              {
                id: 'acctinvoice',
                title: 'Inovice',
                type: 'item',
                icon: 'square',
                // url: 'apps/accounting/entries/income/Invoice'
                url: 'apps/processmap/pm-erp'
              },
              {
                id: 'acctbanktransfer',
                title: 'Transfer',
                type: 'item',
                icon: 'square',
                // url: 'apps/accounting/entries/banking/Transfer'
                url: 'apps/processmap/pm-erp'
              }
            ]
          },

          {
            id: 'acctbank',
            title: 'Banking',
            type: 'collapsible',
            icon: 'circle',
            children: [
              {
                id: 'acctbankacct',
                title: 'Account',
                type: 'item',
                icon: 'square',
                // url: 'apps/accounting/entries/banking/Account'
                url: 'apps/processmap/pm-erp'
              },
              {
                id: 'acctbanktransfer',
                title: 'Transfer',
                type: 'item',
                icon: 'square',
                url: 'apps/accounting/entries/banking/Transfer'
              }
            ]
          },

          {
            id: 'acctbank',
            title: 'Banking',
            type: 'collapsible',
            icon: 'circle',
            children: [
              {
                id: 'acctbankacct',
                title: 'Account',
                type: 'item',
                icon: 'square',
                url: 'apps/accounting/entries/banking/Account'
              },
              {
                id: 'acctbanktransfer',
                title: 'Transfer',
                type: 'item',
                icon: 'square',
                url: 'apps/accounting/entries/banking/Transfer'
              }
            ]
          },

          {
            id: 'acctbank',
            title: 'Banking',
            type: 'collapsible',
            icon: 'circle',
            children: [
              {
                id: 'acctbankacct',
                title: 'Account',
                type: 'item',
                icon: 'square',
                url: 'apps/accounting/entries/banking/Account'
              },
              {
                id: 'acctbanktransfer',
                title: 'Transfer',
                type: 'item',
                icon: 'square',
                url: 'apps/accounting/entries/banking/Transfer'
              }
            ]
          }
        ]
      },

      {
        id: 'acctgrpt',
        title: 'Accounting Reports',
        type: 'collapsible',
        icon: 'box',
        children: [
          {
            id: 'acctrpt10statement',
            title: 'Account Statement',
            type: 'item',
            icon: 'square',
            url: 'apps/accounting/reports/AccountStatement'
          },
          {
            id: 'acctrpt20invcsumm',
            title: 'Invoice Summary',
            type: 'item',
            icon: 'square',
            url: 'apps/accounting/reports/InvoiceSummary'
          },
          {
            id: 'acctrpt30billsumm',
            title: 'Bill Summary',
            type: 'item',
            icon: 'square',
            url: 'apps/accounting/reports/BillSummary'
          },
          {
            id: 'acctrpt40prodstock',
            title: 'Product Stock',
            type: 'item',
            icon: 'square',
            url: 'apps/accounting/reports/ProductStock'
          },
          {
            id: 'acctrpt50profitloss',
            title: 'Profit & Loss',
            type: 'item',
            icon: 'square',
            url: 'apps/accounting/reports/ProfitAndLoss'
          },
          {
          id: 'acctrpt60transaction',
          title: 'Transactiont',
          type: 'item',
          icon: 'square',
          url: 'apps/accounting/reports/TransactionSummary'
          },
          {
            id: 'acctrpt70incsumm',
            title: 'Income Summary',
            type: 'item',
            icon: 'square',
            url: 'apps/accounting/reports/IncomeSummary'
          },
          {
            id: 'acctrpt80expsumm',
            title: 'Expense Summary',
            type: 'item',
            icon: 'square',
            url: 'apps/accounting/reports/ExpenseSummary'
          },
          {
            id: 'acctrpt90incexp',
            title: 'Income vs Expense',
            type: 'item',
            icon: 'square',
            url: 'apps/accounting/reports/IncomeVsExpense'
          },
          {
            id: 'acctrpt100taxsumm',
            title: 'Tax Summary',
            type: 'item',
            icon: 'square',
            url: 'apps/accounting/reports/TaxSummary'
          } 
        ]
      }
      
      
    ]
  },
  {
    id: 'customerentry',
    title: 'Customer',
    type: 'item',
    icon: 'box',
    // url: 'apps/customer/entries/list'
    url: 'apps/processmap/pm-erp'
  },
  
  {
    id: 'vendorentry',
    title: 'Vendor',
    type: 'item',
    icon: 'box',
    // url: 'apps/vendor/entries/list'
    url: 'apps/processmap/pm-erp'
  },

  {
    id: 'prodsys',
    title: 'Product System',
    type: 'item',
    icon: 'box',
    children: [
      {
        id: 'prodsvcs',
        title: 'Product & Services',
        type: 'item',
        icon: 'square',
        // url: 'apps/product/entries/ProdSvc'
        url: 'apps/processmap/pm-erp'
      }
    ]
  },

  {
    id: 'hrdtrentry',
    title: 'HR DTR',
    type: 'item',
    icon: 'box',
    // url: 'apps/humanresource/entries/DailyTimeRecord'
    url: 'apps/processmap/pm-erp'
  },

  {
    id: 'acctproposal',
    title: 'Proposal',
    type: 'item',
    icon: 'box',
    // url: 'apps/accounting/reports/ProfitAndLoss'
    url: 'apps/processmap/pm-erp'
  },

  // Charts 
  {
    id: 'charts',
    type: 'section',
    title: 'Charts',
    icon: 'bar-chart-2',
    children: [
      {
        id: 'sales',
        title: 'Sales',
        type: 'collapsible',
        icon: 'pie-chart',
        children: [
          {
            id: 'apex',
            title: 'Apex',
            type: 'item',
            icon: 'circle',
            url: 'charts/apex'
          }
        ]
      }
    ]
  }
];
